import axios from "axios";
import {setAttempts, setBalance, setIdentifyCode, setUserId} from "../redux/slices/TelegramUserSlice";

export const identifyRequest = (identifyCode) => async (dispatch) =>{
    try {
        const { data } = await axios.post('https://stxlogicalapi.settix.xyz/api/identify', {
            identifyCode
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(setIdentifyCode(identifyCode))
        dispatch(setBalance(data.balance))
        dispatch(setAttempts(data.attemptsCount))
        dispatch(setUserId(data.userId))
    } catch (e) {
        alert('Something went wrong or invalid identify code.')
    }
}