import React from 'react';

const NotFoundPage = () => {
    return (
        <section className="identify_section">
            <div className="welcome_text">
                <span className="h1 semi_bold">404</span>
                <span className="h3">Page not found</span>
            </div>
        </section>
    );
};

export default NotFoundPage;