import React, {useState} from 'react';
import IdentifyNav from "../components/nav/IdentifyNav";
import {useDispatch} from "react-redux";
import {identifyRequest} from "../actions/telegramUserActions";

const IdentifyPage = () => {

    const [identifyCode, setIdentifyCode] = useState()

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(identifyRequest(identifyCode))
    }

    return (
        <>
            <IdentifyNav />
            <section className="identify_section">
                <div className="welcome_text">
                    <span className="h1 regular">Welcome to <span className="semi_bold">Search the STX</span></span>
                    <span className="h3">Write your personal identify code to start mining.</span>
                </div>
                <form onSubmit={submitHandler}>
                    <input type="text" placeholder="xxxx-xxxx-xxxx-xxxx" onChange={e => setIdentifyCode(e.target.value)}/>
                    <button>Identify me</button>
                </form>
            </section>
        </>
    );
};

export default IdentifyPage;