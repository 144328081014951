import React from 'react';
import {Link} from "react-router-dom";

const IdentifyNav = () => {
    return (
        <nav className="identify_nav">
            <Link to="/" className="active">Identity</Link>
            <Link to="https://settix.xyz/stxlogical/search-the-stx/how-to-use" target="_blank">How to use?</Link>
        </nav>
    );
};

export default IdentifyNav;