import {setChosenCard, setGameEnd, setIsStarted, setWinCard} from "../redux/slices/GameSlice";
import axios from "axios";
import {identifyRequest} from "./telegramUserActions";

export const createNewGame = (identifyCode) => async (dispatch) =>{
    try {
        const { data } = await axios.post('https://stxlogicalapi.settix.xyz/api/game', {
            identifyCode
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(setIsStarted(true))
        dispatch(setGameEnd(false))
        dispatch(setChosenCard(0))
        dispatch(setWinCard(false))
        if(data.code === 200) {
            alert(data.message)
        }
        dispatch(identifyRequest(identifyCode))
    } catch (e) {
        if(e.response.data.message) {
            alert(e.response.data.message)
        } else {
            alert('Something went wrong or invalid identify code.')
        }
    }
}

export const chooseCard = (identifyCode, cardIndex) => async (dispatch) =>{
    try {
        const { data } = await axios.put('https://stxlogicalapi.settix.xyz/api/game', {
            identifyCode, cardIndex
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(setChosenCard(cardIndex))
        if(data.win === true) {
            dispatch(setWinCard(true))
        } else {
            dispatch(setWinCard(false))
        }
        dispatch(setGameEnd(true))
    } catch (e) {
        alert('Something went wrong. Try again later!')
    }
}