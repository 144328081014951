import React from 'react';
import faded_robot from "../../assets/img/faded_robot.svg";
import {useDispatch, useSelector} from "react-redux";
import {chooseCard} from "../../actions/gameActions";

const GameCard = ({ cardId, disabled }) => {

    const { identifyCode } = useSelector(state => state.telegramUserController)
    const { isStarted, chosenCard, winCard } = useSelector(state => state.gameController)

    const dispatch = useDispatch()

    const choose = () => {
        if(!disabled) {
            dispatch(chooseCard(identifyCode, cardId))
        }
    }

    return (
        <div className={`card ${(isStarted && chosenCard === cardId) ? 'chosen' : ''} ${disabled ? 'disabled' : ''}`} onClick={choose}>
            {(!isStarted || chosenCard !== cardId) && <img src={faded_robot} alt="settix robot img"/>}
            {(isStarted && chosenCard === cardId && winCard) && <span className="h3 semi_bold">10 STX</span>}
            {(isStarted && chosenCard === cardId && !winCard) && <span className="h3 semi_bold faded_text">0 STX</span>}
        </div>
    );
};

export default GameCard;