import React from 'react';
import GameCard from "./GameCard";
import {useSelector} from "react-redux";

const GameWrap = () => {

    const { gameEnd } = useSelector(state => state.gameController)

    return (
        <section className="game_section">
            <div className="welcome_text">
                <span className="h2 regular">Choose card and try to receive <span className="semi_bold">10 STX</span>!</span>
            </div>
            <div className="game_wrap mt_15">
                <div className="row">
                    <GameCard cardId={1} disabled={gameEnd} />
                    <GameCard cardId={2} disabled={gameEnd} />
                    <GameCard cardId={3} disabled={gameEnd} />
                </div>
                <div className="row">
                    <GameCard cardId={4} disabled={gameEnd} />
                    <GameCard cardId={5} disabled={gameEnd} />
                    <GameCard cardId={6} disabled={gameEnd} />
                </div>
                <div className="row">
                    <GameCard cardId={7} disabled={gameEnd} />
                    <GameCard cardId={8} disabled={gameEnd} />
                    <GameCard cardId={9} disabled={gameEnd} />
                </div>
            </div>
        </section>
    );
};

export default GameWrap;