import './App.css';
import IdentifyPage from "./pages/IdentifyPage";
import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import {useSelector} from "react-redux";
import NotFoundPage from "./pages/NotFoundPage";

function App() {

    const { identifyCode } = useSelector(state => state.telegramUserController)

    return (
        <div className="game_screen">
            {!identifyCode &&
                <Routes>
                    <Route exact path="/" element={<IdentifyPage />} />
                    <Route exact path="*" element={<NotFoundPage />} />
                </Routes>
            }
            {identifyCode &&
                <Routes>
                    <Route exact path="/" element={<MainPage />} />
                    <Route exact path="*" element={<NotFoundPage />} />
                </Routes>
            }
        </div>
    );
}

export default App;
