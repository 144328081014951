import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isStarted: false,
    chosenCard: 0,
    winCard: false,
    gameEnd: false
}

export const gameSlice = createSlice({
    name: 'gameController',
    initialState,
    reducers: {
        setIsStarted: (state, action) => {
            state.isStarted = action.payload;
        },
        setChosenCard: (state, action) => {
            state.chosenCard = action.payload;
        },
        setWinCard: (state, action) => {
            state.winCard = action.payload;
        },
        setGameEnd: (state, action) => {
            state.gameEnd = action.payload;
        },
    }
})

export const { setIsStarted, setChosenCard, setWinCard, setGameEnd } = gameSlice.actions

export default gameSlice.reducer