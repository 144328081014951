import React from 'react';
import stx_logo from '../assets/logo/stx_logo.svg'
import GameWrap from "../components/game/GameWrap";
import {useDispatch, useSelector} from "react-redux";
import {createNewGame} from "../actions/gameActions";

const MainPage = () => {

    const { isStarted, gameEnd } = useSelector(state => state.gameController)
    const { userId, identifyCode, balance, attempts } = useSelector(state => state.telegramUserController)

    const dispatch = useDispatch()

    const formatBalance = (number) => {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(number);
    }

    const copyReferralLink = (id) => {
        navigator.clipboard.writeText(`https://t.me/SearchSTXThroughBot?start=${id}`).then(() => {
            alert('Referral link copied to clipboard. Share this link and receive up to 50% from every referral win game.')
        });
    }

    return (
        <section className="main_section">
            <div className="welcome_text">
                <span className="h2 regular">Welcome back!</span>
            </div>
            <div className="wallet_block mt_15">
                <div className="left">
                    <div className="logo">
                        <img src={stx_logo} alt="settix stx coin logo"/>
                    </div>
                    <div className="coin_name">
                        <span>Settix</span>
                        <span>STX</span>
                    </div>
                </div>
                <div className="right">
                    <span className="h3 semi_bold">{formatBalance(balance)} STX</span>
                </div>
            </div>
            <div className="wallet_block mt_15">
                <div className="left">
                    <div className="coin_name">
                        <span>Attempts:</span>
                        <span>You receive 2 attempts every 30 minutes</span>
                    </div>
                </div>
                <div className="right">
                    <span className="h3 semi_bold">{attempts}</span>
                </div>
            </div>
            <div className="mt_15">
                <span className="mt_15">You can invite a friend and receive bonus from every referrals win game.</span>
            </div>
            <button className="full_width_button mt_15" onClick={() => copyReferralLink(userId)}>Copy referral link</button>
            <div className="hr"></div>
            {!isStarted && <button className="full_width_button" onClick={() => dispatch(createNewGame(identifyCode))}
                                   disabled={attempts === 0}>Use attempt</button>}
            {(isStarted) && <GameWrap/>}
            {gameEnd &&
                <button className="full_width_button mt_15" onClick={() => dispatch(createNewGame(identifyCode))}>Try
                    again</button>}
        </section>
    );
};

export default MainPage;