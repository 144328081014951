import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userId: 0,
    identifyCode: false,
    balance: 0,
    attempts: 0,
}

export const telegramUserSlice = createSlice({
    name: 'telegramUserController',
    initialState,
    reducers: {
        setIdentifyCode: (state, action) => {
            state.identifyCode = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
        setAttempts: (state, action) => {
            state.attempts = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
    }
})

export const { setIdentifyCode, setBalance, setAttempts, setUserId } = telegramUserSlice.actions

export default telegramUserSlice.reducer