import {configureStore} from "@reduxjs/toolkit";
import gameSliceReducer from "./slices/GameSlice";
import telegramUserSliceReducer from "./slices/TelegramUserSlice";

const reducer = {
    telegramUserController: telegramUserSliceReducer,
    gameController: gameSliceReducer,
}

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
});